import React from 'react'

function BookmarkActiveIcon() {
	return (
		<svg
			width='12'
			height='15'
			viewBox='0 0 12 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0 2.1918C0 0.981304 0.981303 0 2.1918 0H9.49781C10.7083 0 11.6896 0.981303 11.6896 2.1918V13.9022C11.6896 14.7935 10.6821 15.312 9.95673 14.7939L5.84481 11.8568L1.73288 14.7939C1.00754 15.312 0 14.7935 0 13.9022V2.1918Z'
				fill='#5386E4'
			/>
		</svg>
	)
}

export default React.memo(BookmarkActiveIcon)
