import React from 'react'
import { NavLink } from 'react-router-dom'
import AnalysisIcon from 'components/icons/AnalysisIcon'
import AnalysisStrokeIcon from 'components/icons/AnalysisStrokeIcon'
import Btn from 'components/common/Btn/Btn'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from '../../../../redux/slices/auth'

function Aside() {
	const sectionLinks = [
		{
			title: 'موقعیت های شغلی',
			icon: <AnalysisStrokeIcon />,
			to: '/jobs',
			activeClass: 'text-white',
			disabled: false
		},
		{
			title: 'نمایشگاه کار',
			icon: <AnalysisIcon />,
			to: '/',
			activeClass: 'text-white',
			disabled: true
		},
		{
			title: 'وبینارها',
			icon: <AnalysisIcon />,
			to: '/',
			activeClass: 'text-white',
			disabled: true
		},
		{
			title: 'مدیریت محتوا',
			icon: <AnalysisIcon />,
			to: '/contentManagement',
			activeClass: 'text-white',
			disabled: false
		}
	]
	const anotherLinks = [
		{
			title: 'درخواست های همکاری',
			icon: <AnalysisIcon />,
			to: '/',
			disabled: true
		},
		{
			title: 'درخواست تعیین سطح',
			icon: <AnalysisIcon />,
			to: '/',
			disabled: true
		},
		{
			title: 'کاربران',
			icon: <AnalysisIcon />,
			to: '/users',
			disabled: false
		}
	]

	const dispatch = useDispatch()
	const loadingBtn = useSelector(state => state.auth.loadingBtn)
	const tickets = useSelector(state => state.tickets)

	return (
		<aside className='flex flex-col justify-start items-start gap-4 px-4 w-full'>
			<div className='w-full'>
				<div className='flex flex-col justify-start items-end gap-2 w-full mb-11'>
					<p className='text-sm text-white'>پنل مدیریت ادمین</p>
					<p className='text-[10px] text-white'>تاریخ امروز : 18مرداد 1401 </p>
				</div>
				<div className='flex flex-col justify-start items-start gap-4 w-full'>
					<button
						disabled
						className='text-gray-400 flex justify-start items-center gap-2'>
						<AnalysisStrokeIcon />
						داشبورد
						{/* <NavLink
							to={'/'}
							className='text-gray-400 flex justify-start items-center gap-2'>
							<span className='bg-dodger p-1 rounded-md'>
								<AnalysisStrokeIcon />{' '}
							</span>{' '}
							داشبورد
						</NavLink> */}
					</button>
					<div className='w-full flex justify-start items-center relative'>
						<p className='text-gray-400 text-base my-4 z-10 bg-yale pl-2'>
							محتوای سایت
						</p>
						<span className='h-[1px] bg-gray-400 w-full absolute'></span>
					</div>
					<div className='w-full flex flex-col justify-start items-start gap-5'>
						{sectionLinks.map(item => (
							<>
								<button disabled={item.disabled}>
									<NavLink
										to={!item.disabled && item.to}
										style={({ isActive }) =>
											isActive ? { color: '#fff' } : undefined
										}
										className={`text-gray-400 flex justify-start items-center gap-2 ${item.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
											}`}>
										<span className='bg-dodger p-1 rounded-md'>
											{item.icon}
										</span>
										{item.title}
									</NavLink>
								</button>
							</>
						))}
					</div>
					<span className='h-[1px] bg-gray-400 w-full my-4'></span>
					<div className='w-full flex flex-col justify-start items-start gap-5'>
						{anotherLinks.map(item => (
							<>
								<button disable={item.disabled}>
									<NavLink
										to={!item.disabled && item.to}
										className={`text-gray-400 flex justify-start items-center gap-2 ${item.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
											}`}>
										<span className='bg-dodger p-1 rounded-md'>
											{item.icon}
										</span>
										{item.title}
									</NavLink>
								</button>
							</>
						))}
					</div>
					<span className='h-[1px] bg-gray-400 w-full my-4'></span>
					<NavLink
						to={'/messages'}
						className='text-gray-400 flex justify-start items-center gap-2'>
						<span className='bg-dodger p-1 rounded-md'>
							<AnalysisIcon />
						</span>
						اعلان ها
					</NavLink>
					<NavLink
						to={'/tickets'}
						className='text-gray-400 flex justify-between w-full items-center gap-2'>
						<span className='flex gap-2 items-center justify-start'>
							<span className='bg-dodger p-1 rounded-md'>
								<AnalysisIcon />
							</span>
							پیام ها
						</span>
						<span className='w-6 h-6 bg-danger flex justify-center items-center rounded-full text-sm'>
							{tickets?.tickets?.meta?.total}
						</span>
					</NavLink>
				</div>
			</div>
			<div className='w-full flex flex-col justify-start items-start gap-4'>
				<NavLink
					to={'/profile/changeProfile'}
					className='text-gray-400 flex justify-start items-center gap-2'>
					<span className='bg-dodger p-1 rounded-md'>
						<AnalysisIcon />
					</span>
					تنظیمات حساب کاربری
				</NavLink>
				<Btn
					onClick={() => dispatch(logOut())}
					loading={loadingBtn}
					disabled={loadingBtn}
					customClass='text-gray-400 flex justify-start items-center gap-2 !text-white !px-0'>
					<span className='bg-dodger p-1 rounded-md'>
						<AnalysisIcon />
					</span>
					خروج
				</Btn>
			</div>
		</aside>
	)
}

export default Aside
