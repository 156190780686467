import React, { useEffect, useRef, useState } from 'react'

// image
import IMAGE from 'assets/images/imageExporter'

// icons
import MagnifierIcon from 'components/icons/MagnifierIcon'
import NotificationIcon from 'components/icons/NotificationIcon'
import UserFill from 'components/icons/userFill'
import LogOut from 'components/icons/LogOut'
// style
import './header.scss'
import ArrowIcon from 'components/icons/ArrowIcon'
import Notification from 'components/common/Notification/Notification'
import { useDispatch, useSelector } from 'react-redux'
import { setDepartemanId } from 'redux/slices/departeman'
import { Link, useLocation } from 'react-router-dom'
import Passwrod from 'components/icons/Password'
import Btn from '../../Btn/Btn'
import { logOut } from '../../../../redux/slices/auth'
import { useOutsideClickCustom } from '../../../../hooks/useOutsideClick'
import { useRouteMatch } from "react-router-dom";

function Header() {
	const profileRef = useRef()
	const notificationRef = useRef()
	const searchInput = useRef()

	const location = useLocation()

	const [inputSearch, setInputSearch] = useState(false)
	const [notification, setNotification] = useState(false)
	const [profile, setProfile] = useState(false)

	const userInfo = JSON.parse(localStorage.getItem('userInfo'))
	const departments = useSelector(state => state.departments.departemans)
	const departmentsId = useSelector(state => state.departments.departemanId)
	const loadingBtn = useSelector(state => state.auth.loadingBtn)

	const dispatch = useDispatch()


	const notAllowedUrl = [
		'/profile/changeProfile',
		'/departeman/jobs',
		'/contentManagement',
		'/profile/changePassword',
		'/messages'
	]

	const checkUrl = () => {
		for (let i = 0; i < notAllowedUrl.length; i++) {
			if (notAllowedUrl[i] === location.pathname) {
				return false
			}
		}
	}
	useOutsideClickCustom(profileRef, setProfile)
	useOutsideClickCustom(notificationRef, setNotification)


	return (
		<header>
			<div className='logo__btns py-2 px-6'>
				<img className='mashghool_logo' src={IMAGE.mashghool} alt='logo' />

				<div className='icons__user'>
					{/* <div
						onClick={() => searchInput.current.focus()}
						className='search_box'>
						<span>
							<MagnifierIcon />
						</span>
						<input
							ref={searchInput}
							onBlur={() => setInputSearch(false)}
							onFocus={() => setInputSearch(true)}
							type='text'
							className={!inputSearch && 'disable'}
						/>
					</div> */}
					<button
						ref={notificationRef}
						className='notification relative'
						onClick={() => setNotification(!notification)}>
						<NotificationIcon />
						{notification && <Notification />}
					</button>

					<div
						ref={profileRef}
						className='drop_down cursor-pointer relative'
						onClick={() => setProfile(!profile)}>
						<div className='img_name__arrow'>
							<div className='img__name '>
								<img
									src={IMAGE.userPhoto}
									alt='profile'
									className='w-6 h-6 rounded-full'
								/>
								<span>
									{userInfo?.name} {userInfo?.last_name}
								</span>
							</div>
							<ArrowIcon />
						</div>
						{profile && (
							<div className='w-64 rounded-lg bg-white p-2 absolute left-0 shadow-lg top-10 z-50 cursor-default'>
								<div className='w-full border-b border-solid border-gray-500 flex gap-2 justify-end items-center p-2'>
									<p>
										{userInfo?.name} {userInfo?.last_name}
									</p>
									<img
										src={IMAGE.userPhoto}
										alt='userPhoti'
										className='w-12 h-12 rounded-lg'
									/>
								</div>
								<div className='flex flex-col justify-start items-stretch w-full gap-2 p-1 mt-3'>
									<Link
										to='/profile/changeProfile'
										className='w-full p-2 hover:bg-gray-100 flex justify-start items-start gap-2'>
										<UserFill />
										پروفایل
									</Link>
									<Link
										to='/profile/changePassword'
										className='w-full p-2 hover:bg-gray-100 flex justify-start items-start gap-2'>
										<Passwrod />
										تغییر پسورد
									</Link>
									<Btn
										block
										customClass='!justify-start !px-2  hover:bg-gray-100'
										onClick={() => dispatch(logOut())}
										loading={loadingBtn}
										disabled={loadingBtn}>
										<LogOut />
										خروج
									</Btn>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{checkUrl() === false ? null : (
				<nav>
					<ul>
						{departments &&
							(window.location.pathname === "/jobs" || window.location.pathname === '/tickets' ? departments.slice(0, -1) : departments).map(departeman => (
								<li
									key={departeman.id}
									className={departmentsId === departeman.id ? 'active' : null}>
									<button
										onClick={() => dispatch(setDepartemanId(departeman.id))}>
										{departeman.title}
									</button>
								</li>
							))}
					</ul>
				</nav>
			)}
		</header>
	)
}

export default Header
