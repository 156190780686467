import React from 'react'
import ReactDOM from 'react-dom/client'

// redux
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux/store'

// setup reporter
import reportWebVitals from './config/reportWebVitals'

// router
import Router from './router/router'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				{/* <PersistGate loading={null} persistor={persistor}> */}
				<ToastContainer
					position='top-right'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={true}
					pauseOnFocusLoss
					draggable
					pauseOnHover
				>
				</ToastContainer>
					<Router />

				{/* </PersistGate> */}
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
