const Passwrod = ({ color }) => {
	return (
		<svg
			width='10'
			height='20'
			viewBox='0 0 10 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M4 9.89998C1.71776 9.43671 0 7.41896 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.41896 8.28224 9.43671 6 9.89998V13H8C8.55228 13 9 13.4477 9 14C9 14.5523 8.55228 15 8 15H6V16H8C8.55228 16 9 16.4477 9 17C9 17.5523 8.55228 18 8 18H6V19C6 19.5523 5.55228 20 5 20C4.44772 20 4 19.5523 4 19V9.89998Z'
				fill='#356899'
			/>
		</svg>
	)
}

export default Passwrod
