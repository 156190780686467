import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'helper/axiosInstance'

export const fetchUsers = createAsyncThunk(
	'/users/fetchUsers',
	async ({ departmentsId, sort, rank, searchText, page }) => {
		const response = await axiosInstance.get(
			`/admin/user/index?department_id=${departmentsId !== 0 ? departmentsId : ' '}&page=${page}${sort ? `&sort=${sort}` : ''
			}${rank ? `&rank_response=${rank}` : ''}${searchText ? `&searchText=${searchText}` : ''
			}`
		)
		return response
	}
)

export const jobAppliedUser = createAsyncThunk(
	'/users/jobAppliedUser',
	async applyId => {
		const response = axiosInstance.get(`/admin/job/user/apply/${applyId}`)
		return response
	}
)

export const adminShow = createAsyncThunk('/users/adminShow', async () => {
	const response = axiosInstance.get('/admin/user/show/admin')
	return response
})

export const userShow = createAsyncThunk('/users/userShow', async id => {
	const response = axiosInstance.get(`/admin/user/show/${id}`)
	return response
})

export const userJobsNote = createAsyncThunk(
	'/users/userJobsNote',
	async ({ note, applyId }) => {
		const response = axiosInstance.post(`/admin/job/apply/note/${applyId}`, {
			note
		})
		return response
	}
)

export const jobAppliedUpdate = createAsyncThunk(
	'/users/jobAppliedUpdate',
	async ({ applyId, responseStatus }) => {
		const response = axiosInstance.patch(
			`/admin/user/job/applied/update/${applyId}`,
			{ responseStatus }
		)
		return response
	}
)

export const createRanking = createAsyncThunk(
	'/users/createRanking',
	async ({ id, rank }) => {
		const response = axiosInstance.post(`/admin/user/ranking/${id}`, { rank })
		return response
	}
)

export const updatePassword = createAsyncThunk(
	'/user/updatePassword',
	async ({ newPassword }) => {
		const response = axiosInstance.patch('/admin/user/update/password', {
			newPassword
		})
		return response
	}
)

export const updateUser = createAsyncThunk(
	'/users/updateUser',
	async ({ data, id }) => {
		const response = await axiosInstance.patch(`/admin/user/update/${id}`, {
			data
		})
		return response
	}
)

const usersSlice = createSlice({
	name: 'users',
	initialState: {
		users: null,
		status: 'idle',
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchUsers.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchUsers.fulfilled, (state, action) => {
				state.status = 'completed'
				if (action.payload.status === 200) {
					state.users = action.payload.data.users
					// console.log(state.users)
				}
			})
			.addCase(fetchUsers.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
	}
})

export const selectAllUsers = state => state.users.users

export default usersSlice.reducer
