import React from 'react'

function ArrowIcon() {
	return (
		<svg
			width='12'
			height='15'
			viewBox='0 0 12 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M1.5 0C0.671572 0 0 0.671574 0 1.5V13.5C0 14.3284 0.671573 15 1.5 15H10.5C11.3284 15 12 14.3284 12 13.5V3.31066C12 2.91284 11.842 2.5313 11.5607 2.25L9.75 0.43934C9.4687 0.158035 9.08716 0 8.68934 0H1.5ZM1.5 1.5L8.68934 1.5L10.5 3.31066V13.5H1.5V1.5ZM9.17875 6.59477C9.47165 6.30188 9.47165 5.827 9.17875 5.53411C8.88586 5.24122 8.41099 5.24122 8.11809 5.53411L4.93611 8.71609L3.87545 7.65543C3.58256 7.36254 3.10769 7.36254 2.81479 7.65543C2.5219 7.94832 2.5219 8.4232 2.81479 8.71609L4.35275 10.254C4.67493 10.5762 5.1973 10.5762 5.51948 10.254L9.17875 6.59477Z'
				fill='#AFB0B6'
			/>
		</svg>
	)
}

export default React.memo(ArrowIcon)
