import React from 'react'

function AnalysisIcon() {
	return (
		<svg
			width='20'
			height='18'
			viewBox='0 0 20 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9 0C7.89543 0 7 0.89543 7 2V18H13V2C13 0.895431 12.1046 0 11 0H9Z'
				fill='#356899'
			/>
			<path
				d='M15 5H18C19.1046 5 20 5.89543 20 7V16C20 17.1046 19.1046 18 18 18H15V5Z'
				fill='#356899'
			/>
			<path
				d='M0 10C0 8.89543 0.895431 8 2 8H5V18H2C0.89543 18 0 17.1046 0 16V10Z'
				fill='#356899'
			/>
		</svg>
	)
}

export default React.memo(AnalysisIcon)
