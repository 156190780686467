import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { selectAllNotifications } from 'redux/slices/notifications'

const Notification = () => {
	const notifications = useSelector(selectAllNotifications)
	return (
		<div className='absolute w-96 rounded-lg bg-white shadow-lg top-10 left-0 p-2 z-50 h-60 overflow-auto'>
			<div className='w-full flex flex-col justify-start items-start gap-1'>
				{notifications.data.length > 0 &&
					notifications.data.map((message, index) => (
						<div
							className='w-full hover:bg-gray-200 transition-all p-3 rounded-lg'
							key={index}>
							<p className='text-sm text-start'>{message.data.note_message}</p>
						</div>
					))}
				{notifications.data.length === 0 && (
					<p className='text-gray-500 font-normal p-4'>موردی یافت نشد</p>
				)}

				{notifications.length > 0 && (
					<div className='w-full px-5 py-2'>
						<NavLink to='/messages' className='underline text-primary text-sm'>
							مشاهده همه
						</NavLink>
					</div>
				)}
			</div>
		</div>
	)
}

export default Notification
