import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'helper/axiosInstance'
import { toast } from 'react-toastify'

export const fetchJobs = createAsyncThunk('/jobs/fetchJobs', async params => {
	const response = await axiosInstance.get(
		`/admin/job/index?page=${params.page}${
			params.sort ? `&sort=${params.sort}` : ''
		}${params.search ? `&searchText=${params.search}` : ''}${
			params.departemanId ? `&department_id=${params.departemanId}` : ''
		}${params.status !== 'marked' ? `&status=${params.status}` : ''}${
			params.marked ? '&marked=yes' : ''
		}`
	)
	return response
})

export const getJobApply = createAsyncThunk('/jobs/getJobApply', async id => {
	const response = await axiosInstance.get(`/admin/job/apply/${id}`)
	return response
})

export const showJob = createAsyncThunk('/jobs/showJob', async id => {
	const response = await axiosInstance.get(`/admin/job/delete/${id}`)
	return response
})
export const deleteJob = createAsyncThunk(
	'/jobs/deleteJob',
	async ({ id }, { dispatch, getState }) => {
		const response = await axiosInstance.delete(`/admin/job/delete/${id}`)
		if (response.status === 200) {
			toast.success('پست مورد نظر با موفقیت حذف شد.')
			dispatch(
				fetchJobs({
					page: 1,
					departemanId: getState().departments.departemanId
				})
			)
		}
		return response
	}
)

export const archiveJob = createAsyncThunk(
	'/jobs/archiveJob',
	async ({ id }, { dispatch, getState }) => {
		const response = await axiosInstance.delete(`/admin/job/archive/${id}`)
		if (response.status === 200) {
			toast.success('شغل مورد نظر آرشیو شد.')
			dispatch(
				fetchJobs({
					page: 1,
					departemanId: getState().departments.departemanId
				})
			)
		}
		return response
	}
)

export const createOrUpdateJob = createAsyncThunk(
	'/jobs/createOrUpdateJob',
	async ({ job, id }, { dispatch, getState }) => {
		const response = await axiosInstance.post(
			`/admin/job/store${id ? `/${id}` : ''}`,
			job
		)
		if (response.status === 200) {
			toast.success(id ? 'شغل با موفقیت ویرایش شد.' : 'شغل با موفقیت ایجاد شد.')
			dispatch(
				fetchJobs({
					page: 1,
					departemanId: getState().departments.departemanId,
					status: ''
				})
			)
			dispatch(clearNewJob())
		}
		return response
	}
)

export const updateStatus = createAsyncThunk(
	'/job/updateStatus',
	async ({ id, status, marked }, { dispatch, getState }) => {
		const response = await axiosInstance.patch(
			`/admin/job/status/update/${id}`,
			{ status, marked }
		)
		if (response.status === 200)
			dispatch(
				fetchJobs({
					search: '',
					sort: '',
					departemanId: getState().departments.departemanId,
					status: '',
					page: 1
				})
			)
		return response
	}
)

const jobsSlice = createSlice({
	name: 'jobs',
	initialState: {
		status: 'idle',
		statusCreate: 'idle',
		statusDelete: 'idle',
		statusUpdate: 'idle',
		loadingBtn: false,
		error: null,
		jobs: null,
		newJob: {
			jobInfo: {
				title: '',
				department_id: '',
				category_id: '',
				upload_id: '',
				experience: '',
				level: '',
				special: 'no',
				salary_type: '',
				min_salary: '',
				max_salary: '',
				status: 'available'
			},
			jobCondition: {
				type: '',
				coop_method: '',
				start_date: '',
				end_date: '',
				description: '',
				responsibility: ''
			},
			jobSoftware: [],
			jobTag: []
		}
	},
	reducers: {
		setNewJob: (state, action) => {
			state.newJob = action.payload
		},
		setStatus: (state, action) => {
			state.newJob.jobInfo.status = action.payload
		},

		clearNewJob: state => {
			for (const key in state.newJob.jobInfo) {
				state.newJob.jobInfo[key] = ''
			}
			for (const key in state.newJob.jobCondition) {
				state.newJob.jobCondition[key] = ''
			}
			state.newJob.jobSoftware = []
			state.newJob.jobTag = []
			state.newJob.jobInfo.status = 'available'
			state.newJob.jobInfo.special = 'no'
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchJobs.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchJobs.fulfilled, (state, action) => {
				state.status = 'completed'
				if (action.payload.status === 200) {
					state.jobs = action.payload.data
				} else {
					state.error = action.payload.message
				}
			})
			.addCase(fetchJobs.rejected, (state, action) => {
				state.status = 'rejected'
				state.error = action.error.message
			})
			.addCase(createOrUpdateJob.pending, state => {
				state.loadingBtn = true
				state.statusCreate = 'loading'
			})
			.addCase(createOrUpdateJob.fulfilled, (state, action) => {
				state.loadingBtn = false
				state.statusCreate = 'completed'
			})
			.addCase(createOrUpdateJob.rejected, (state, action) => {
				state.loadingBtn = false
				state.error = action.error.message
				state.statusCreate = 'failed'
			})
			.addCase(deleteJob.pending, state => {
				state.loadingBtn = true
				state.statusDelete = 'loading'
			})
			.addCase(deleteJob.fulfilled, (state, action) => {
				state.loadingBtn = false
				state.statusDelete = 'completed'
			})
			.addCase(deleteJob.rejected, (state, action) => {
				state.loadingBtn = false
				state.statusDelete = 'failed'
				state.error = action.error.message
			})
			.addCase(updateStatus.pending, state => {
				state.statusUpdate = 'loading'
				state.loadingBtn = true
			})
			.addCase(updateStatus.fulfilled, (state, action) => {
				state.statusUpdate = 'completed'
				state.loadingBtn = false
				if (action.payload.status === 200) {
					toast.success('عملیات با موفقیت انجام شد.')
				}
			})
			.addCase(updateStatus.rejected, (state, action) => {
				state.loadingBtn = false
				state.statusUpdate = 'failed'
				state.error = action.error.message
			})
			.addCase(archiveJob.pending, state => {
				state.loadingBtn = true
			})
			.addCase(archiveJob.fulfilled, state => {
				state.loadingBtn = false
			})
			.addCase(archiveJob.rejected, (state, action) => {
				state.loadingBtn = false
				state.error = action.error.message
			})
	}
})

export const selectAllJobs = state => state.jobs.jobs
export const { setNewJob, clearNewJob, setStatus } = jobsSlice.actions
export default jobsSlice.reducer
