import React, { lazy, Suspense } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import App from '../App'
import Icons from 'pages/Icon'
import ProtectedRoute from './ProtectedRoute'
import ProgressCircle from '../components/common/ProgressCircle/ProgressCircle'

const Jobs = lazy(() => import('../pages/Jobs/Jobs'))
const Requests = lazy(() => import('../pages/Requests/Requests'))
const Login = lazy(() => import('../pages/Login/Login'))
const ForgetPassword = lazy(() =>
	import('../pages/ForgetPassword/ForgetPassword')
)
const ChangePassword = lazy(() =>
	import('../pages/ForgetPassword/changePassword/changePassword')
)
const ContentManagement = lazy(() =>
	import('../pages/ContentManagement/ContentManagement')
)
const Departeman = lazy(() =>
	import('pages/ContentManagement/Departeman/Departeman')
)
const DepartemanJob = lazy(() =>
	import('pages/ContentManagement/Departeman/DepartemanJob/DepartemanJob')
)
const ManagementTextContent = lazy(() =>
	import(
		'pages/ContentManagement/Departeman/ManagementTextContent/ManagementTextContent'
		)
)
const Users = lazy(() => import('pages/Users/Users'))
const Messages = lazy(() => import('pages/Messages/Messages'))
const Tickets = lazy(() => import('pages/Tickets/Tickets'))
const Profile = lazy(() => import('pages/Profile/Profile'))
const ChangeProfile = lazy(() =>
	import('pages/Profile/ChangeProfile/ChangeProfile')
)
const ChangePasswordProfile = lazy(() =>
	import('pages/Profile/ChangePassword/ChangePassword')
)
const AlarmSetting = lazy(() =>
	import('pages/Profile/AlarmSetting/AlarmSetting')
)

function Router() {
	return (
		<BrowserRouter>
			<Suspense
				fallback={
					<div className='w-full h-[100vh] flex justify-center items-center'>
						<div className='lds-dual-ring'></div>
					</div>
				}>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/forgetPassword' element={<ForgetPassword />} />
					<Route path='/changePassword' element={<ChangePassword />} />
					<Route path='/' element={<ProtectedRoute component={<App />} />}>
						<Route
							path='/jobs'
							index
							element={<ProtectedRoute component={<Jobs />} />}></Route>
						<Route
							path='/requests'
							element={<ProtectedRoute component={<Requests />} />}
						/>

						<Route
							path='/contentManagement'
							element={<ProtectedRoute component={<ContentManagement />} />}
						/>
						<Route
							path='/departeman'
							element={<ProtectedRoute component={<Departeman />} />}>
							<Route
								path='jobs'
								element={<ProtectedRoute component={<DepartemanJob />} />}
							/>
							<Route
								path='managementTextContent'
								element={
									<ProtectedRoute component={<ManagementTextContent />} />
								}
							/>
							<Route
								path='managementImageContent'
								element={<ProtectedRoute component={<DepartemanJob />} />}
							/>
							<Route
								path='managementUserContent'
								element={<ProtectedRoute component={<DepartemanJob />} />}
							/>
							<Route
								path='tags'
								element={<ProtectedRoute component={<DepartemanJob />} />}
							/>
						</Route>
						<Route
							path='/users'
							element={<ProtectedRoute component={<Users />} />}
						/>
						<Route
							path='/messages'
							element={<ProtectedRoute component={<Messages />} />}
						/>
						<Route
							path='/tickets'
							element={<ProtectedRoute component={<Tickets />} />}
						/>
						<Route
							path='/profile'
							element={<ProtectedRoute component={<Profile />} />}>
							<Route
								path='changeProfile'
								index
								element={<ProtectedRoute component={<ChangeProfile />} />}
							/>
							<Route
								path='changePassword'
								element={
									<ProtectedRoute component={<ChangePasswordProfile />} />
								}
							/>
							<Route
								path='alarmSetting'
								element={<ProtectedRoute component={<AlarmSetting />} />}
							/>
						</Route>
						<Route path='/icons' element={<Icons />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}

export default Router
