// router
import Layout from 'components/common/Layout/Layout'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { fetchCategory } from 'redux/slices/category'

// main style
import './style/App.scss'
import { fetchNotifications } from './redux/slices/notifications'
import { fetchTickets } from './redux/slices/tickets'

function App() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	// navigate('/jobs')
	useEffect(() => {
		dispatch(fetchCategory())
		dispatch(fetchNotifications({ page: 1 }))
		dispatch(fetchTickets({ page: 1 }))
		if (location.pathname === '/') {
			navigate('/jobs')
		}
	}, [dispatch])
	return (
		<>
			<Layout>
				<Outlet />
			</Layout>
		</>
	)
}

export default App
