import React from 'react'

function CalenderIcon() {
	return (
		<svg
			width='14'
			height='16'
			viewBox='0 0 14 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M1.22913 6.26833H13.1118'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M10.128 8.8731H10.1342'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M7.16973 8.8731H7.1759'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M4.20415 8.87249H4.21033'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M10.128 11.4641H10.1342'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M7.16973 11.4641H7.1759'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M4.20415 11.4636H4.21033'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M9.86349 1.33333V3.52718'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M4.47799 1.33333V3.52718'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M9.99214 2.38541H4.34727C2.38947 2.38541 1.16663 3.47603 1.16663 5.48076V11.5139C1.16663 13.5501 2.38947 14.6659 4.34727 14.6659H9.98596C11.9499 14.6659 13.1666 13.569 13.1666 11.5643V5.48076C13.1728 3.47603 11.9561 2.38541 9.99214 2.38541Z'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default React.memo(CalenderIcon)
