import React from 'react'

function DownloadIcon() {
	return (
		<svg
			width='18'
			height='20'
			viewBox='0 0 18 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9 0C9.55229 0 10 0.447715 10 1V11.8284L11.8284 9.99996C12.219 9.60944 12.8521 9.60944 13.2426 9.99996C13.6332 10.3905 13.6332 11.0237 13.2426 11.4142L9.88388 14.7729C9.39573 15.2611 8.60427 15.2611 8.11612 14.7729L4.75736 11.4142C4.36684 11.0237 4.36684 10.3905 4.75736 9.99996C5.14788 9.60944 5.78105 9.60944 6.17157 9.99996L8 11.8284V1C8 0.447715 8.44771 0 9 0Z'
				fill='#09244B'
			/>
			<path
				d='M0 5C0 3.89543 0.895431 3 2 3H5V5H2V18H16V5H13V3H16C17.1046 3 18 3.89543 18 5V18C18 19.1046 17.1046 20 16 20H2C0.89543 20 0 19.1046 0 18V5Z'
				fill='#09244B'
			/>
		</svg>
	)
}

export default React.memo(DownloadIcon)
