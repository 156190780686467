import React from 'react'

function MagnifierIcon({ color = '#fff' }) {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.75 16.25C12.8921 16.25 16.25 12.8921 16.25 8.75C16.25 4.60786 12.8921 1.25 8.75 1.25C4.60786 1.25 1.25 4.60786 1.25 8.75C1.25 12.8921 4.60786 16.25 8.75 16.25Z'
				stroke={color}
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M14.375 14.375L18.75 18.75'
				stroke={color}
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default React.memo(MagnifierIcon)
