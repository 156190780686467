const IMAGE = {
	mashghool: require('./mashghool.png'),
	user: require('./user.png'),
	requestAideBg: require('./requestAideBg.png'),
	Linkedin: require('./linkedin.png'),
	cv: require('./cv.png'),
	behance: require('./behance.png'),
	pen: require('./pen.png'),
	settingCheck: require('./settingCheck.png'),
	teamWork: require('./teamWork.png'),
	terminal: require('./terminal.png'),
	userPhoto: require('./userPhoto.png')
}

export default IMAGE
