import React from 'react'

function BookmarkIcon() {
	return (
		<svg
			width='12'
			height='15'
			viewBox='0 0 12 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M0 2.1918C0 0.981304 0.981303 0 2.1918 0H9.49781C10.7083 0 11.6896 0.981303 11.6896 2.1918V13.9022C11.6896 14.7935 10.6821 15.312 9.95673 14.7939L5.84481 11.8568L1.73288 14.7939C1.00754 15.312 0 14.7935 0 13.9022V2.1918ZM2.1918 1.4612C1.7883 1.4612 1.4612 1.7883 1.4612 2.1918V13.1923L5.20783 10.5162C5.58887 10.244 6.10074 10.244 6.48178 10.5162L10.2284 13.1923V2.1918C10.2284 1.7883 9.90131 1.4612 9.49781 1.4612H2.1918Z'
				fill='#AFB0B6'
			/>
		</svg>
	)
}

export default React.memo(BookmarkIcon)
