import AddIcon from 'components/icons/AddIcon'
import AnalysisIcon from 'components/icons/AnalysisIcon'
import AnalysisStrokeIcon from 'components/icons/AnalysisStrokeIcon'
import ArrowIcon from 'components/icons/ArrowIcon'
import ArrowWithLine from 'components/icons/ArrowWithLine'
import BookmarkActiveIcon from 'components/icons/BookmarkActiveIcon'
import BookmarkAddIcon from 'components/icons/BookmarkAddIcon'
import BookmarkIcon from 'components/icons/BookmarkIcon'
import BoxWithCheckIcon from 'components/icons/BoxWithCheckIcon'
import CalenderIcon from 'components/icons/CalenderIcon'
import ChatIcon from 'components/icons/ChatIcon'
import CheckStarIcon from 'components/icons/CheckStarIcon'
import CirclePlusIcon from 'components/icons/CirclePlusIcon'
import ClockIcon from 'components/icons/ClockIcon'
import CloseIcon from 'components/icons/CloseIcon'
import CloseDocumentIcon from 'components/icons/CloseDocumentIcon'
import DocumentIcon from 'components/icons/DocumentIcon'
import DownloadIcon from 'components/icons/DownloadIcon'
import EmailSendIcon from 'components/icons/EmailSendIcon'
import FilterIcon from 'components/icons/FilterIcon'
import MagnifierIcon from 'components/icons/MagnifierIcon'
import NotificationIcon from 'components/icons/NotificationIcon'
import ShareIcon from 'components/icons/ShareIcon'
import WalletIcon from 'components/icons/WalletIcon'

const Icons = () => {
	return (
		<>
			<div className='flex gap-3 text-lg'>
				<AddIcon />
				<AnalysisIcon />
				<AnalysisStrokeIcon />
				<ArrowIcon />
				<ArrowWithLine />
				<BookmarkActiveIcon />
				<BookmarkAddIcon />
				<BookmarkIcon />
				<BoxWithCheckIcon />
				<CalenderIcon />
				<ChatIcon />
				<CheckStarIcon />
				<CirclePlusIcon />
				<ClockIcon />
				<CloseDocumentIcon />
				<CloseIcon />
				<DocumentIcon />
				<DownloadIcon />
				<EmailSendIcon />
				<FilterIcon />
				<MagnifierIcon />
				<NotificationIcon />
				<ShareIcon />
				<WalletIcon />
			</div>
		</>
	)
}

export default Icons
