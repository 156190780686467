import axiosInstance from '../helper/axiosInstance'

export const subCategoryAPI = async (id, page, search, sort, isPaginate) => {
	const response = await axiosInstance.get(
		`/admin/category/index/${isPaginate ? 'slug' : 'subs'}?department_id=${id}${
			isPaginate ? `&page=${page}` : ''
		}${search !== '' ? `&searchText=${search}` : ''}${
			sort ? `&sort=${sort}` : ''
		}`
	)
	return response
}
