import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTickets } from '../../services/tickets'

export const fetchTickets = createAsyncThunk('tickets/getTickets', async ({ page = 1 }) => {
	const response = await getTickets(page)
	return response
})


const ticketsSlice = createSlice({
	name: 'tickets',
	initialState: {
		loading: true,
		error: false,
		tickets: []
	},
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchTickets.pending, (state) => {
			state.loading = true
		})
			.addCase(fetchTickets.fulfilled, (state, action) => {
				state.tickets = action.payload.data
				state.loading = false
			})
			.addCase(fetchTickets.rejected, (state, action) => {
				state.loading = false
				state.error = true
			})
	}
})

export default ticketsSlice.reducer