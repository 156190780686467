import React from 'react'

function CheckStarIcon({ color = '#5386E4' }) {
	return (
		<svg
			width='19'
			height='17'
			viewBox='0 0 19 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M18.2083 8.5L16.2766 6.29125L16.5458 3.37L13.6879 2.72083L12.1916 0.1875L9.49996 1.34333L6.80829 0.1875L5.31204 2.71292L2.45413 3.35417L2.72329 6.28333L0.791626 8.5L2.72329 10.7087L2.45413 13.6379L5.31204 14.2871L6.80829 16.8125L9.49996 15.6488L12.1916 16.8046L13.6879 14.2792L16.5458 13.63L16.2766 10.7087L18.2083 8.5ZM7.42579 11.6746L5.54163 9.77458C5.46824 9.70134 5.41001 9.61435 5.37028 9.51858C5.33056 9.42281 5.31011 9.32014 5.31011 9.21646C5.31011 9.11277 5.33056 9.01011 5.37028 8.91434C5.41001 8.81857 5.46824 8.73157 5.54163 8.65833L5.59704 8.60292C5.90579 8.29417 6.41246 8.29417 6.72121 8.60292L7.99579 9.88542L12.0729 5.80042C12.3816 5.49167 12.8883 5.49167 13.197 5.80042L13.2525 5.85583C13.5612 6.16458 13.5612 6.66333 13.2525 6.97208L8.56579 11.6746C8.24121 11.9833 7.74246 11.9833 7.42579 11.6746Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(CheckStarIcon)
