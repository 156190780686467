import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'helper/axiosInstance'

export const fetchNotifications = createAsyncThunk(
	'notifications/fetchNotifications',
	async ({ page = 1 }) => {
		const response = await axiosInstance.get(
			`/admin/notification/index?page=${page}`
		)
		return response
	}
)

export const getNotification = createAsyncThunk(
	'/notifications/getNotification',
	async ({ id }) => {
		const response = axiosInstance.get(`/admin/notification/show/${id}`)
		return response
	}
)

const notificationsSlice = createSlice({
	name: 'notifications',
	initialState: {
		status: 'idle',
		notifications: [],
		error: null
	},
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(fetchNotifications.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchNotifications.fulfilled, (state, action) => {
				state.status = 'completed'
				if (action.payload.status === 200) {
					console.log('action.payload', action.payload);
					state.notifications = action.payload.data
				} else {
					state.error = action.payload.data.message
				}
			})
			.addCase(fetchNotifications.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
	}
})

export const selectAllNotifications = state => state.notifications.notifications

export default notificationsSlice.reducer
