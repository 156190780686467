import React from 'react'

function NotificationIcon() {
	return (
		<svg
			width='18'
			height='22'
			viewBox='0 0 18 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M15.875 11.9212V10.0625H14.5V12.2188C14.5 12.4094 14.5725 12.5921 14.7014 12.7269L16.5625 14.6726V15.8125H1.4375V14.6726L3.29856 12.7269C3.4275 12.5921 3.49996 12.4094 3.5 12.2188V9.34375C3.49808 8.33376 3.75114 7.34112 4.23362 6.4661C4.7161 5.59108 5.41091 4.86467 6.24787 4.36023C7.08483 3.85579 8.03429 3.59119 9.00037 3.59316C9.96645 3.59513 10.9149 3.86359 11.75 4.37144V2.76503C11.0956 2.46209 10.3995 2.26901 9.6875 2.19291V0.71875H8.3125V2.19219C6.61752 2.37255 5.04671 3.20355 3.90382 4.52452C2.76092 5.84548 2.12713 7.56257 2.125 9.34375V11.9212L0.263937 13.8668C0.134997 14.0016 0.0625389 14.1844 0.0625 14.375V16.5312C0.0625 16.7219 0.134933 16.9047 0.263864 17.0395C0.392795 17.1743 0.567664 17.25 0.75 17.25H5.5625V17.9688C5.5625 18.9219 5.92466 19.836 6.56932 20.5099C7.21398 21.1839 8.08832 21.5625 9 21.5625C9.91168 21.5625 10.786 21.1839 11.4307 20.5099C12.0753 19.836 12.4375 18.9219 12.4375 17.9688V17.25H17.25C17.4323 17.25 17.6072 17.1743 17.7361 17.0395C17.8651 16.9047 17.9375 16.7219 17.9375 16.5312V14.375C17.9375 14.1844 17.865 14.0016 17.7361 13.8668L15.875 11.9212ZM11.0625 17.9688C11.0625 18.5406 10.8452 19.0891 10.4584 19.4935C10.0716 19.8978 9.54701 20.125 9 20.125C8.45299 20.125 7.92839 19.8978 7.54159 19.4935C7.1548 19.0891 6.9375 18.5406 6.9375 17.9688V17.25H11.0625V17.9688Z'
				fill='#95969D'
			/>
		</svg>
	)
}

export default React.memo(NotificationIcon)
