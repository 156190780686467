import { createSlice } from '@reduxjs/toolkit'

const departemanSlice = createSlice({
	name: 'departments',
	initialState: {
		departemans: [],
		departemanId: 1
	},
	reducers: {
		setDepartemans: (state, action) => {
			state.departemans = action.payload
		},
		setDepartemanId: (state, action) => {
			state.departemanId = action.payload
		}
	}
})

export const selectDepartemanById = (id, state) =>
	state.departments.departemans.find(departeman => departeman.id === id)

export const { setDepartemanId, setDepartemans } = departemanSlice.actions
export default departemanSlice.reducer
