import React from 'react'

function CloseIcon({ color = '#fff', className, onClick }) {
	return (
		<svg
			onClick={() => onClick()}
			className={className}
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10.3212 8.50001L15.8753 2.95876C16.1186 2.71553 16.2552 2.38565 16.2552 2.04167C16.2552 1.6977 16.1186 1.36781 15.8753 1.12459C15.6321 0.881363 15.3022 0.74472 14.9583 0.74472C14.6143 0.74472 14.2844 0.881363 14.0412 1.12459L8.49992 6.67876L2.95867 1.12459C2.71545 0.881363 2.38556 0.74472 2.04159 0.74472C1.69761 0.74472 1.36773 0.881363 1.1245 1.12459C0.881279 1.36781 0.744636 1.6977 0.744636 2.04167C0.744636 2.38565 0.881279 2.71553 1.1245 2.95876L6.67867 8.50001L1.1245 14.0413C1.00344 14.1613 0.907346 14.3042 0.84177 14.4616C0.776194 14.619 0.742432 14.7878 0.742432 14.9583C0.742432 15.1289 0.776194 15.2977 0.84177 15.4551C0.907346 15.6125 1.00344 15.7553 1.1245 15.8754C1.24458 15.9965 1.38744 16.0926 1.54484 16.1582C1.70224 16.2237 1.87107 16.2575 2.04159 16.2575C2.2121 16.2575 2.38093 16.2237 2.53833 16.1582C2.69573 16.0926 2.83859 15.9965 2.95867 15.8754L8.49992 10.3213L14.0412 15.8754C14.1613 15.9965 14.3041 16.0926 14.4615 16.1582C14.6189 16.2237 14.7877 16.2575 14.9583 16.2575C15.1288 16.2575 15.2976 16.2237 15.455 16.1582C15.6124 16.0926 15.7553 15.9965 15.8753 15.8754C15.9964 15.7553 16.0925 15.6125 16.1581 15.4551C16.2237 15.2977 16.2574 15.1289 16.2574 14.9583C16.2574 14.7878 16.2237 14.619 16.1581 14.4616C16.0925 14.3042 15.9964 14.1613 15.8753 14.0413L10.3212 8.50001Z'
				fill={color}
			/>
		</svg>
	)
}

export default React.memo(CloseIcon)
