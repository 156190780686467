import React from 'react'

function CloseDocumentIcon() {
	return (
		<svg
			width='12'
			height='15'
			viewBox='0 0 12 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.625 10.5C5.41789 10.5 5.25 10.6679 5.25 10.875V11.625C5.25 11.8321 5.41789 12 5.625 12H6.375C6.58211 12 6.75 11.8321 6.75 11.625V10.875C6.75 10.6679 6.58211 10.5 6.375 10.5H5.625Z'
				fill='#AFB0B6'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M1.5 0C0.671572 0 0 0.671574 0 1.5V13.5C0 14.3284 0.671573 15 1.5 15H10.5C11.3284 15 12 14.3284 12 13.5V1.5C12 0.671573 11.3284 0 10.5 0H1.5ZM1.5 1.5L10.5 1.5V13.5H1.5V1.5Z'
				fill='#AFB0B6'
			/>
		</svg>
	)
}

export default React.memo(CloseDocumentIcon)
