import React from 'react'

function EmailSendIcon() {
	return (
		<svg
			width='14'
			height='10'
			viewBox='0 0 14 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M2.5 0C1.80964 0 1.25 0.559644 1.25 1.25V2.5H2.5V2.13388L6.83713 6.47102C7.20325 6.83714 7.79684 6.83713 8.16296 6.47102L12.5 2.13398V8.75H2.5V8.125H1.25V8.75C1.25 9.44036 1.80964 10 2.5 10H12.5C13.1904 10 13.75 9.44036 13.75 8.75V1.25C13.75 0.559644 13.1904 0 12.5 0H2.5ZM11.6162 1.25H3.38388L7.50005 5.36616L11.6162 1.25Z'
				fill='#95969D'
			/>
			<path
				d='M1.25 3.75C0.904822 3.75 0.625 4.02982 0.625 4.375C0.625 4.72018 0.904822 5 1.25 5H3.125C3.47018 5 3.75 4.72018 3.75 4.375C3.75 4.02982 3.47018 3.75 3.125 3.75H1.25Z'
				fill='#95969D'
			/>
			<path
				d='M0 6.25C0 5.90482 0.279822 5.625 0.625 5.625H3.75C4.09518 5.625 4.375 5.90482 4.375 6.25C4.375 6.59518 4.09518 6.875 3.75 6.875H0.625C0.279822 6.875 0 6.59518 0 6.25Z'
				fill='#95969D'
			/>
		</svg>
	)
}

export default React.memo(EmailSendIcon)
