import React from 'react'

function ArrowWithLine() {
	return (
		<svg
			width='19'
			height='14'
			viewBox='0 0 19 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.0606 0.425044L17.7174 5.89783C18.3032 6.46455 18.3032 7.38339 17.7174 7.95012L12.0606 13.4229C11.4748 13.9896 10.525 13.9896 9.93924 13.4229C9.35346 12.8562 9.35345 11.9373 9.93924 11.3706L13.0354 8.37516H1.5C0.671573 8.37516 0 7.72544 0 6.92397C0 6.1225 0.671573 5.47278 1.5 5.47278H13.0354L9.93924 2.47734C9.35345 1.91061 9.35346 0.991769 9.93924 0.425044C10.525 -0.141681 11.4748 -0.141681 12.0606 0.425044Z'
				fill='#09244B'
			/>
		</svg>
	)
}

export default ArrowWithLine
