import React from 'react'

function BookmarkAddIcon() {
	return (
		<svg
			width='15'
			height='17'
			viewBox='0 0 15 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M15 4.5C15 5.69347 14.5259 6.83807 13.682 7.68198C12.8381 8.52589 11.6935 9 10.5 9C9.30653 9 8.16193 8.52589 7.31802 7.68198C6.47411 6.83807 6 5.69347 6 4.5C6 3.30653 6.47411 2.16193 7.31802 1.31802C8.16193 0.474106 9.30653 0 10.5 0C11.6935 0 12.8381 0.474106 13.682 1.31802C14.5259 2.16193 15 3.30653 15 4.5ZM11 2.5C11 2.36739 10.9473 2.24021 10.8536 2.14645C10.7598 2.05268 10.6326 2 10.5 2C10.3674 2 10.2402 2.05268 10.1464 2.14645C10.0527 2.24021 10 2.36739 10 2.5V4H8.5C8.36739 4 8.24022 4.05268 8.14645 4.14645C8.05268 4.24021 8 4.36739 8 4.5C8 4.63261 8.05268 4.75979 8.14645 4.85355C8.24022 4.94732 8.36739 5 8.5 5H10V6.5C10 6.63261 10.0527 6.75979 10.1464 6.85355C10.2402 6.94732 10.3674 7 10.5 7C10.6326 7 10.7598 6.94732 10.8536 6.85355C10.9473 6.75979 11 6.63261 11 6.5V5H12.5C12.6326 5 12.7598 4.94732 12.8536 4.85355C12.9473 4.75979 13 4.63261 13 4.5C13 4.36739 12.9473 4.24021 12.8536 4.14645C12.7598 4.05268 12.6326 4 12.5 4H11V2.5ZM10.5 10C11.0072 10.0005 11.5119 9.93083 12 9.793V16.5C11.9999 16.5922 11.9744 16.6826 11.9261 16.7611C11.8779 16.8397 11.8089 16.9034 11.7267 16.9452C11.6446 16.987 11.5525 17.0053 11.4606 16.998C11.3687 16.9907 11.2806 16.9582 11.206 16.904L6 13.118L0.794 16.904C0.719435 16.9582 0.631335 16.9907 0.539436 16.998C0.447537 17.0053 0.355417 16.987 0.273255 16.9452C0.191094 16.9034 0.122089 16.8397 0.0738659 16.7611C0.0256429 16.6826 7.93406e-05 16.5922 0 16.5V3.5C0 2.83696 0.263392 2.20107 0.732233 1.73223C1.20107 1.26339 1.83696 1 2.5 1H6.257C5.59323 1.80462 5.17194 2.7814 5.04229 3.81639C4.91264 4.85137 5.07998 5.90189 5.52478 6.84537C5.96958 7.78885 6.6735 8.5864 7.55442 9.14496C8.43534 9.70352 9.45693 10.0001 10.5 10Z'
				fill='white'
			/>
		</svg>
	)
}

export default React.memo(BookmarkAddIcon)
