import React from 'react'

function ClockIcon() {
	return (
		<svg
			width='15'
			height='14'
			viewBox='0 0 15 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M13.6666 7.00033C13.6666 10.4063 10.9059 13.167 7.49992 13.167C4.09392 13.167 1.33325 10.4063 1.33325 7.00033C1.33325 3.59433 4.09392 0.833664 7.49992 0.833664C10.9059 0.833664 13.6666 3.59433 13.6666 7.00033Z'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M9.78665 8.96277L7.27332 7.46343V4.2321'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default React.memo(ClockIcon)
