import React from 'react'

function ShareIcon() {
	return (
		<svg
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M13 3.5C13 1.567 14.567 0 16.5 0C18.433 0 20 1.567 20 3.5C20 5.433 18.433 7 16.5 7C15.5174 7 14.6293 6.59506 13.9936 5.94296L9.66987 8.20952C9.88313 8.76541 10 9.36905 10 10C10 10.6309 9.88314 11.2346 9.66987 11.7905L13.9936 14.057C14.6293 13.4049 15.5174 13 16.5 13C18.433 13 20 14.567 20 16.5C20 18.433 18.433 20 16.5 20C14.567 20 13 18.433 13 16.5C13 16.2702 13.0221 16.0455 13.0644 15.8281L8.58852 13.4817C7.67983 14.4181 6.40787 15 5 15C2.23858 15 0 12.7614 0 10C0 7.23858 2.23858 5 5 5C6.40786 5 7.67982 5.58187 8.58851 6.51824L13.0644 4.17191C13.0222 3.95446 13 3.72982 13 3.5ZM16.5 2C15.6716 2 15 2.67157 15 3.5C15 4.32843 15.6716 5 16.5 5C17.3284 5 18 4.32843 18 3.5C18 2.67157 17.3284 2 16.5 2ZM2 10C2 8.34315 3.34315 7 5 7C6.65685 7 8 8.34315 8 10C8 11.6569 6.65685 13 5 13C3.34315 13 2 11.6569 2 10ZM15 16.5C15 15.6716 15.6716 15 16.5 15C17.3284 15 18 15.6716 18 16.5C18 17.3284 17.3284 18 16.5 18C15.6716 18 15 17.3284 15 16.5Z'
				fill='#09244B'
			/>
		</svg>
	)
}

export default React.memo(ShareIcon)
