import React from 'react'

function CirclePlusIcon() {
	return (
		<svg
			width='21'
			height='20'
			viewBox='0 0 21 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.5 10C5.5 9.44771 5.94772 9 6.5 9H9.5V6C9.5 5.44771 9.94771 5 10.5 5C11.0523 5 11.5 5.44771 11.5 6V9H14.5C15.0523 9 15.5 9.44771 15.5 10C15.5 10.5523 15.0523 11 14.5 11H11.5V14C11.5 14.5523 11.0523 15 10.5 15C9.94771 15 9.5 14.5523 9.5 14V11H6.5C5.94772 11 5.5 10.5523 5.5 10Z'
				fill='#09244B'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10ZM10.5 2C6.08172 2 2.5 5.58172 2.5 10C2.5 14.4183 6.08172 18 10.5 18C14.9183 18 18.5 14.4183 18.5 10C18.5 5.58172 14.9183 2 10.5 2Z'
				fill='#09244B'
			/>
		</svg>
	)
}

export default React.memo(CirclePlusIcon)
