import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { setIsloggedIn, setToken, setUserInfo } from 'redux/slices/auth'

const ProtectedRoute = ({ component }) => {
	const token = localStorage.getItem('loggedInToken') && localStorage.getItem('loggedInToken')
	const userInfo = localStorage.getItem('userInfo')
	const dispatch = useDispatch()
	if (token === null || !token) {
		localStorage.removeItem('userInfo')
		localStorage.removeItem('loggedInToken')
		return <Navigate to='/login' replace />
	}
	dispatch(setToken(token))
	dispatch(setIsloggedIn(true))
	dispatch(setUserInfo(JSON.parse(userInfo)))

	return <>{component}</>
}

export default ProtectedRoute
