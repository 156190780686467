import React from 'react'

function ChatIcon() {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M3 4.5C3 4.22386 3.22386 4 3.5 4H6.5C6.77614 4 7 4.22386 7 4.5C7 4.77614 6.77614 5 6.5 5H3.5C3.22386 5 3 4.77614 3 4.5Z'
				fill='#95969D'
			/>
			<path
				d='M3.5 6C3.22386 6 3 6.22386 3 6.5C3 6.77614 3.22386 7 3.5 7H5C5.27614 7 5.5 6.77614 5.5 6.5C5.5 6.22386 5.27614 6 5 6H3.5Z'
				fill='#95969D'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5C10 7.76142 7.76142 10 5 10H1C0.447715 10 0 9.55228 0 9V5ZM5 1C2.79086 1 1 2.79086 1 5V9H5C7.20914 9 9 7.20914 9 5C9 2.79086 7.20914 1 5 1Z'
				fill='#95969D'
			/>
		</svg>
	)
}

export default React.memo(ChatIcon)
