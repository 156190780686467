import './Btn.scss'
const Btn = ({
	children,
	primary,
	onClick,
	bordered,
	customClass,
	lg,
	block,
	link,
	loading,
	disabled,
	type
}) => {
	return (
		<button
			type={type}
			onClick={(e) => onClick(e)}
			className={`outline-none border-none  py-2 px-4 text-sm flex justify-center items-center gap-2 ${
				primary ? 'bg-primary text-white' : 'text-black'
			} ${
				bordered ? '!border !border-solid !text-midnight border-midnight' : null
			} ${customClass} ${block ? 'w-full' : null} ${
				link ? 'text-primary underline' : null
			}`}
			disabled={disabled}>
			{children}
			{loading && <div className='lds-dual-ring'></div>}
		</button>
	)
}

export default Btn
