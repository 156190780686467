import React from 'react'

function FilterIcon() {
	return (
		<svg
			width='17'
			height='21'
			viewBox='0 0 17 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M4.66671 14.3333H0.833374L6.58337 20.0833V0.916626H4.66671V14.3333ZM10.4167 3.79163V20.0833H12.3334V6.66663H16.1667L10.4167 0.916626V3.79163Z'
				fill='#2F569B'
			/>
		</svg>
	)
}

export default React.memo(FilterIcon)
