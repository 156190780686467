import React from 'react'

function DocumentIcon() {
	return (
		<svg
			width='12'
			height='15'
			viewBox='0 0 12 15'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M0 1.5C0 0.671574 0.671572 0 1.5 0H10.5C11.3284 0 12 0.671573 12 1.5V13.5C12 14.3284 11.3284 15 10.5 15H1.5C0.671573 15 0 14.3284 0 13.5V1.5ZM10.5 1.5L1.5 1.5V13.5H10.5V1.5ZM3 5.25C3 4.83579 3.33579 4.5 3.75 4.5H8.25C8.66421 4.5 9 4.83579 9 5.25C9 5.66421 8.66421 6 8.25 6H3.75C3.33579 6 3 5.66421 3 5.25ZM3.75 8.25C3.33579 8.25 3 8.58579 3 9C3 9.41421 3.33579 9.75 3.75 9.75H6C6.41421 9.75 6.75 9.41421 6.75 9C6.75 8.58579 6.41421 8.25 6 8.25H3.75Z'
				fill='#AFB0B6'
			/>
		</svg>
	)
}

export default React.memo(DocumentIcon)
