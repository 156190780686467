import React from 'react'

function AnalysisStrokeIcon() {
	return (
		<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C6 0.89543 6.89543 0 8 0H12C13.1046 0 14 0.89543 14 2V5H18C19.1046 5 20 5.89543 20 7V16C20 17.1046 19.1046 18 18 18H2C0.895431 18 0 17.1046 0 16V10C0 8.89543 0.895431 8 2 8H6V2ZM12 16V2H8V16H12ZM14 16H18V7H14V16ZM6 10V16H2V10H6Z" fill="#356899"/>
		</svg>
		
	)
}

export default React.memo(AnalysisStrokeIcon)
