import { combineReducers } from '@reduxjs/toolkit'
import authSlice from './slices/auth'
import categorySlice from './slices/category'
import usersSlice from './slices/users'
import notificationsSlice from './slices/notifications'
import jobsSlice from './slices/jobs'
import departemanSlice from './slices/departeman'
import ticketsSlice from './slices/tickets'

export const rootReducer = combineReducers({
	auth: authSlice,
	categories: categorySlice,
	users: usersSlice,
	notifications: notificationsSlice,
	jobs: jobsSlice,
	departments: departemanSlice,
	tickets: ticketsSlice
})
