import { useEffect } from 'react'

export const useOutsideClickCustom = (ref, handleClick) => {
	useEffect(() => {

		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handleClick(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}