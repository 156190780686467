import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'helper/axiosInstance'
import { toast } from 'react-toastify'
import { setDepartemans } from './departeman'
import { subCategoryAPI } from '../../services/categoryAPI'

export const fetchCategory = createAsyncThunk(
	'/category/fetchCategory',
	async (params, { dispatch }) => {
		const response = await axiosInstance.get('/admin/category/index/subs')
		const temp = [...response.data.jobPosts, { id: 0, title: 'کاربران جدید' }];
		dispatch(setDepartemans(temp))
		return response
	}
)

export const fetchCategoryById = createAsyncThunk(
	'/category/fetchCategoryById',
	async ({ id, page, search = '', sort, isPaginate }) => {
		const response = await subCategoryAPI(id, page, search, sort, isPaginate)
		return response
	}
)

export const createCategory = createAsyncThunk(
	'/category/createCategory',
	async data => {
		const response = await axiosInstance.post('/admin/category/store', data)
		return response
	}
)

export const updateSubCategory = createAsyncThunk(
	'/category/updateCategory',
	async ({ id, subCategory }, { dispatch }) => {
		const response = await axiosInstance.patch(
			`/admin/category/update/${id}`,
			subCategory
		)
		if (response.status === 200)
			dispatch(
				fetchCategoryById({
					id: subCategory.parent_id,
					page: 1,
					isPaginate: true
				})
			)

		return response
	}
)

export const deleteCategory = createAsyncThunk(
	'/category/deleteCategory',
	async ({ id, parentId }, { dispatch }) => {
		const response = await axiosInstance.delete(`/admin/category/delete/${id}`)
		if (response.status === 200) {
			toast.success(response.data.Error)
			dispatch(fetchCategoryById({ id: parentId, page: 1, isPaginate: true }))
		}
		return response
	}
)

const categorySlice = createSlice({
	name: 'category',
	initialState: {
		categories: [],
		subCategories: [],
		categoryId: null,
		status: 'idle',
		subCategoryStatus: 'idle',
		statusCreate: 'idle',
		statusUpdate: 'idle',
		loadingBtn: false,
		error: null
	},
	reducers: {
		setCategoryId: (state, action) => {
			state.categoryId = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchCategory.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchCategory.fulfilled, (state, action) => {
				state.status = 'completed'
				if (action.payload.status === 200) {
					state.categories = action.payload.data.jobPosts
					state.categoryId = 1
				} else {
					state.error = action.payload.data.messsage
				}
			})
			.addCase(fetchCategory.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
			.addCase(fetchCategoryById.pending, state => {
				state.subCategoryStatus = 'loading'
			})
			.addCase(fetchCategoryById.fulfilled, (state, action) => {
				state.subCategoryStatus = 'completed'
				if (action.payload.status === 200) {
					state.subCategories = action.payload.data.jobPosts
				} else {
					state.error = action.payload.data.messsage
				}
			})
			.addCase(fetchCategoryById.rejected, (state, action) => {
				state.subCategoryStatus = 'failed'
				state.error = action.error.message
			})

			.addCase(createCategory.pending, state => {
				state.statusCreate = 'loading'
				state.loadingBtn = true
			})
			.addCase(createCategory.fulfilled, (state, action) => {
				state.statusCreate = 'completed'
				state.loadingBtn = false
				if (action.payload.status === 200) {
					toast.success('عنوان شغل با موفقیت ایجاد شد.')
				}
			})
			.addCase(createCategory.rejected, (state, action) => {
				state.statusCreate = 'failed'
				state.error = action.error.message
				state.loadingBtn = false
				toast.error('عملیات با خطا مواجه شد.')
			})
			.addCase(deleteCategory.pending, state => {
				state.loadingBtn = true
			})
			.addCase(deleteCategory.fulfilled, (state, action) => {
				state.loadingBtn = false
			})
			.addCase(deleteCategory.rejected, (state, action) => {
				state.loadingBtn = false
				state.error = action.error.message
			})
			.addCase(updateSubCategory.pending, state => {
				state.loadingBtn = true
				state.statusUpdate = 'loading'
			})
			.addCase(updateSubCategory.fulfilled, (state, action) => {
				state.loadingBtn = false
				state.statusUpdate = 'completed'
				if (action.payload.status === 200) {
					toast.success('شغل مورد نظر با موفقیت ویرایش شد.')
				}
			})
			.addCase(updateSubCategory.rejected, (state, action) => {
				state.loadingBtn = false
				state.statusUpdate = 'failed'
				state.error = action.error.message
			})
	}
})

export const selectAllCategories = state => state.categories.categories
export const selectAllSubCategories = state => state.categories.subCategories
export const { setCategoryId } = categorySlice.actions
export default categorySlice.reducer
