import React from 'react'

function AddIcon() {
	return (
		<svg
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clip-path='url(#clip0_440_9995)'>
				<path
					d='M1.59375 15.4062V4.78125H0.53125V15.6719C0.531487 15.8831 0.61552 16.0857 0.764911 16.2351C0.914303 16.3845 1.11685 16.4685 1.32812 16.4688H12.2188V15.4062H1.59375Z'
					fill='white'
				/>
				<path
					d='M4.78125 13.2812H3.71875V2.65625H2.65625V13.5469C2.65649 13.7581 2.74052 13.9607 2.88991 14.1101C3.0393 14.2595 3.24185 14.3435 3.45312 14.3438H14.3438V13.2812H4.78125Z'
					fill='white'
				/>
				<path
					d='M15.6719 0.53125H5.57812C5.36685 0.531487 5.1643 0.61552 5.01491 0.764911C4.86552 0.914303 4.78149 1.11685 4.78125 1.32812V11.4219C4.78149 11.6331 4.86552 11.8357 5.01491 11.9851C5.1643 12.1345 5.36685 12.2185 5.57812 12.2188H15.6719C15.8831 12.2185 16.0857 12.1345 16.2351 11.9851C16.3845 11.8357 16.4685 11.6331 16.4688 11.4219V1.32812C16.4685 1.11685 16.3845 0.914303 16.2351 0.764911C16.0857 0.61552 15.8831 0.531487 15.6719 0.53125ZM15.4062 11.1562H5.84375V1.59375H15.4062V11.1562Z'
					fill='white'
				/>
				<path
					d='M10.0938 9.5625H11.1562V6.77344H13.9453V5.71094H11.1562V2.92188H10.0938V5.71094H7.30469V6.77344H10.0938V9.5625Z'
					fill='white'
				/>
			</g>
			<defs>
				<clipPath id='clip0_440_9995'>
					<rect width='17' height='17' fill='white' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default React.memo(AddIcon)
