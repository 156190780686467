import React from 'react'

function WalletIcon() {
	return (
		<svg
			width='16'
			height='14'
			viewBox='0 0 16 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.2592 8.59618H11.5603C10.5693 8.59557 9.76611 7.79296 9.7655 6.80199C9.7655 5.81103 10.5693 5.00842 11.5603 5.00781H14.2592'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M11.8656 6.76192H11.6578'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M4.99832 1H10.7606C12.6927 1 14.259 2.56634 14.259 4.49844V9.28313C14.259 11.2152 12.6927 12.7816 10.7606 12.7816H4.99832C3.06622 12.7816 1.49988 11.2152 1.49988 9.28313V4.49844C1.49988 2.56634 3.06622 1 4.99832 1Z'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
			<path
				d='M4.52356 4.02544H8.12286'
				stroke='#B3BAC5'
				stroke-width='1.5'
				stroke-linecap='round'
				stroke-linejoin='round'
			/>
		</svg>
	)
}

export default React.memo(WalletIcon)
