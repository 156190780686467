const LogOut = ({color = "fff"}) => {
    return (
			<svg
				width='18'
				height='18'
				viewBox='0 0 18 18'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M11.5144 5.15986V4.38236C11.5144 2.68652 10.1394 1.31152 8.44354 1.31152H4.38104C2.68604 1.31152 1.31104 2.68652 1.31104 4.38236V13.6574C1.31104 15.3532 2.68604 16.7282 4.38104 16.7282H8.45187C10.1427 16.7282 11.5144 15.3574 11.5144 13.6665V12.8807'
					stroke='#356899'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M17.1753 9.02018H7.14111'
					stroke='#356899'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M14.7354 6.5918L17.1754 9.02096L14.7354 11.451'
					stroke='#356899'
					stroke-width='1.5'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
			</svg>
		)
}
 
export default LogOut;