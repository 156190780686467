import React, { useEffect } from 'react'

// style
import './Layout.scss'

// component
import Seo from '../Seo'
import Container from '../Container/Container'
import Aside from './Aside/Aside'
import Header from './Header/Header'

function Layout({ children, title }) {
	return (
		<main className='layout'>
			<Seo title={title} />

			<Container>
				<section className='aside__main_content'>
					<Aside />

					<section className='main_content__header'>
						<Header />
						{children}
					</section>
				</section>
			</Container>
		</main>
	)
}

export default Layout
