import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'helper/axiosInstance'
import { toast } from 'react-toastify'

export const fetchLogin = createAsyncThunk(
	'/auth/fetchLogin',
	async ({ email, password }) => {
		const response = await axiosInstance.post('auth/login', { email, password })
		return response
	}
)

export const logOut = createAsyncThunk('/auth/logout', async () => {
	const response = await axiosInstance.post('/auth/logout')
	return response
})

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		isLoggedIn: false,
		token: null,
		userInfo: null,
		error: null,
		status: 'idle',
		loadingBtn: false
	},
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload
		},
		setUserInfo: (state, action) => {
			state.userInfo = action.payload
		},
		setIsloggedIn: (state, action) => {
			state.isLoggedIn = action.payload
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchLogin.pending, state => {
				state.status = 'loading'
			})
			.addCase(fetchLogin.fulfilled, (state, action) => {
				state.status = 'completed'
				if (action.payload.status === 200) {
					state.isLoggedIn = true
					state.error = null
					state.token = action.payload.data.token
					state.userInfo = action.payload.data.user
					localStorage.setItem('loggedInToken', action.payload.data.token)
					localStorage.setItem(
						'userInfo',
						JSON.stringify(action.payload.data.user)
					)
				} else {
					state.error = action.payload.data.message
				}
			})
			.addCase(fetchLogin.rejected, (state, action) => {
				state.status = 'failed'
				state.error = action.error.message
			})
			.addCase(logOut.pending, (state) => {
				state.status = 'loading'
				state.loadingBtn = true
			})
			.addCase(logOut.fulfilled, (state, action) => {
				state.status = 'completed'
				state.loadingBtn = false

				if (action.payload.status === 200) {
					toast.success('از اکانت خود خارج شدید')
					localStorage.removeItem('userInfo')
					localStorage.removeItem('loggedInToken')
					setTimeout(() => {
						window.location.reload()
					}, 4000)
				}
			})
			.addCase(logOut.rejected, (state, action) => {
				state.loadingBtn = false
				state.error = action.error.message
				state.status = 'failed'
			})
	}
})

export const { setToken, setIsloggedIn, setUserInfo } = authSlice.actions

export default authSlice.reducer
