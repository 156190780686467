const UserFill = ({ color = 'fff' }) => {
	return (
		<svg
			width='14'
			height='17'
			viewBox='0 0 14 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M7 0C4.85223 0 3.11111 1.90279 3.11111 4.25C3.11111 6.59721 4.85223 8.5 7 8.5C9.14777 8.5 10.8889 6.59721 10.8889 4.25C10.8889 1.90279 9.14777 0 7 0Z'
				fill='#356899'
			/>
			<path
				d='M3.88889 10.2C1.74112 10.2 0 12.1028 0 14.45V15.3C0 16.2389 0.696446 17 1.55556 17H12.4444C13.3036 17 14 16.2389 14 15.3V14.45C14 12.1028 12.2589 10.2 10.1111 10.2H3.88889Z'
				fill='#356899'
			/>
		</svg>
	)
}

export default UserFill
