import React from 'react'

// style
import './container.scss'

function Container({ children }) {
	return <section className='container'>{children}</section>
}

export default Container
